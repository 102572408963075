(function ($) {
    let $body =  $('body');

    // show and hide mobile menu
    const burgerIcon = document.querySelector('.icon-hamburger');
    const mobileMenu = document.querySelector('.navigation-mobile');
    const closeIcon = document.querySelector('.icon-close');
    burgerIcon.addEventListener('click', showMobileMenu);
    closeIcon.addEventListener('click', hideMobileMenu);

    function showMobileMenu() {
        document.body.classList.add('open-menu');
        burgerIcon.style.setProperty("display", "none", "important");
    }

    function hideMobileMenu() {
        document.body.classList.remove('open-menu');
        burgerIcon.style.setProperty("display", "block", "important");
    }

    // show and hide burger icon after rotation
    if (typeof screen.orientation === "object") {
        screen?.orientation.addEventListener('change', function (e) {
            document.body.classList.remove('open-menu');
            if (screen.width < 768) {
                burgerIcon.style.setProperty("display", "block", "important");
            } else {
                burgerIcon.style.setProperty("display", "none", "important");
                mobileMenu.style.display = "none";
                closeIcon.style.display = "none";
            }
        })
    }

    // open and close pop-up with video
    const openPopupButton = document.querySelector('.hero-play-button');
    const popup = document.querySelector('.popup-video');
    const closePopupButton = document.querySelector('.close-video-button');
    const videoIframe = document.querySelector('.hero-video iframe');

    if(openPopupButton) {
        openPopupButton.addEventListener('click', showPopup);
    }

    if(closePopupButton) {
        closePopupButton.addEventListener('click', hidePopup);
    }

    function showPopup() {
        popup.style.display = 'flex';
        document.body.style.overflow = 'hidden';
        videoIframe.setAttribute('width', '1280px');
        videoIframe.setAttribute('height', '720px');
        //videoIframe.setAttribute('height', '90vh');
    }

    function hidePopup() {
        videoIframe.setAttribute('src', videoIframe.getAttribute('src'));
        popup.style.display = 'none';
        document.body.style.overflow = 'visible';
    }

    $body.on('click', '.video-list .item', '', (e) => {
        let title = e.target.dataset?.title;
        $('.video-list .item').css('display', 'block');
        $('#' + e.target.id).css('display', 'none');
        $('#actual-video').html(`<iframe width="100%" height="510" src="https://player.vimeo.com/video/${e.target.id}"                                  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe><h3>${title}</h3>`)
    });

    $body.on('click', '.accordion .item .title', '', (e) => {
        const parentEl = e.target.closest('.item');
        const descriptionHeight = $(parentEl).find('.description')[0].offsetHeight;
        const parentAccordion = e.target.closest('.accordion');
        $(parentAccordion.querySelectorAll('.item')).removeClass('opened').css('height', 60 + 'px')
        $(parentEl).css('height', descriptionHeight + 85 + 'px');
        $(parentEl).addClass('opened');
    });

    $body.on('click', '.accordion .item.opened .title', '', (e) => {
        const parentEl = e.target.closest('.item');
        const parentAccordion = e.target.closest('.accordion');
        $(parentAccordion.querySelector('.item')).removeClass('opened').css('height', 60 + 'px')
        $(parentEl).css('height', '60px');
        $(parentEl).removeClass('opened');
    });

    if ($('.photo-product-slider').length) {
        $('.photo-product-slider').slick({
            fade: true,
            nextArrow: '<span class="arrow next-slide"><svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.7071 8.70711C41.0976 8.31658 41.0976 7.68342 40.7071 7.29289L34.3431 0.928932C33.9526 0.538408 33.3195 0.538408 32.9289 0.928932C32.5384 1.31946 32.5384 1.95262 32.9289 2.34315L38.5858 8L32.9289 13.6569C32.5384 14.0474 32.5384 14.6805 32.9289 15.0711C33.3195 15.4616 33.9526 15.4616 34.3431 15.0711L40.7071 8.70711ZM0 9H40V7H0L0 9Z"/></svg></sspan>',
            prevArrow: '<span class="arrow prev-slide"><svg width="41" height="16" viewBox="0 0 41 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM41 7L1 7V9L41 9V7Z"/></svg></sspan>',
        });
    }

    $body.on('click', '.photo-product-slider-nav img', '', (e) => {
        let index = e.target.dataset?.index;
        $('.photo-product-slider')[0].slick.slickGoTo(parseInt(index));
        $('.photo-product-slider-nav img').removeClass('active');
        $(`#photo-slide-${index}`).addClass('active');
    });

    $('.photo-product-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.photo-product-slider-nav img').removeClass('active');
        $(`#photo-slide-${nextSlide}`).addClass('active');
    });

    function initSlider1() {
        let mql = window.matchMedia("(orientation: portrait)");
        if ($('.slider-1 .features').length && screen.width < 992 && mql.matches) {
            $('.slider-1 .features').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
            })
        }
    }

    function initSlider2() {
        let mql = window.matchMedia("(orientation: portrait)");
        if ($('.slider-2 .features').length && screen.width < 992 && mql.matches) {
            $('.slider-2 .features').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
            })
        }
    }

    if ($('.video-slider').length) {
        $('.video-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true
        })
    }

    addEventListener('orientationchange', (event) => {
        if (screen.height < 992) {
            initSlider1();
            initSlider2();
        } else {
            $('.slider-1 .features').slick('unslick');
            $('.slider-2 .features').slick('unslick');
        }
    });

    initSlider1();
    initSlider2();

    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
        let headerHeight = $('.header').height();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - headerHeight
        }, 500);
    });

    if ($('.perfect-pizza-table.mobile').length > 0 && $(window).width() <= 980) {
        $('.perfect-pizza-table.mobile .right-column').slick({
            arrows: false,
            infinite: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            centerPadding: '10px',
            centerMode: true,
            mobileFirst: true,
            responsive: [
                // {
                //     breakpoint: 980,
                //     settings: {
                //         slidesToShow: 3,
                //         slidesToScroll: 1,
                //     }
                // },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        })
    }

    $(document).on('click', '.list-item-name', function (){
        let $target = $(this).attr("data-link");
        $('.image-wrapper .item').removeClass('show');
        $('.image-wrapper .item.' + $target).addClass('show');
        $('.list-item-name').removeClass('red');
        $(this).addClass('red');
    })

    document.addEventListener("DOMContentLoaded", function() {
        $('[data-link=the-works]').addClass('red');
    });

    // color dots for availability table
    const optionsList = [...document.querySelectorAll('.option')];

    optionsList.forEach(el => {
        if (el.textContent === 'LIMITED' || el.textContent === 'VERY LIMITED') {
            el.previousSibling.style.backgroundColor = '#F2C450';
        }
        if (el.textContent === 'Fully Reserved') {
            el.previousSibling.style.backgroundColor = '#CA2121';
        }
    })

})(jQuery);
